/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, {useEffect, useRef, useState} from 'react';
import TableRow from '@mui/material/TableRow';
import {isEqual, omit} from 'lodash';
import MemoizedScannerCell from '../Scanner/ScannerCell';


const isAddedToWatchlist = ({activeWatchlistSymbols, value}) => {
  if (!activeWatchlistSymbols?.length || !Array.isArray(activeWatchlistSymbols)) return false;
  return activeWatchlistSymbols?.includes(value);
};


const WatchlistRow = ({row, dataTypes, activeWatchlistSymbols, orderByState, orderState, optionsColumns, optionsAllowed, lockedColumns, allowedChart, useAI}) => {
  const cellRef = useRef(null);
  const [filteredDataTypes, setFilteredDataTypes] = useState([]);
  const [addedToWatchlist, setIsAddedToWatchlist] = useState(false);

  useEffect(() => {
    if (!dataTypes?.length) return;
    setFilteredDataTypes(dataTypes.filter((dt) => !dt?.hideColumn));
  }, [dataTypes]);

  useEffect(() => {
    const isAdded = isAddedToWatchlist({
      activeWatchlistSymbols,
      value: row?.Symbol?.value,
    });
    if (addedToWatchlist !== isAdded) {
      setIsAddedToWatchlist(isAdded);
    }
  }, [activeWatchlistSymbols, row]);

  return (
    <TableRow hover tabIndex={-1} ref={cellRef} id={row?.Symbol?.value}>
      {filteredDataTypes?.length
        ? filteredDataTypes.map((dataType, index) => (
            <MemoizedScannerCell
              key={dataType.key}
              rowValue={row}
              dataType={dataType}
              dataTypes={dataTypes}
              optionsColumns={optionsColumns}
              row={row}
              index={index}
              optionsAllowed={optionsAllowed}
              lockedColumns={lockedColumns}
              allowedChart={allowedChart}
              groupName={row?.parentGroup}
              addedToWatchlist={dataType?.key === 'Symbol'}
              order={orderState}
              orderBy={orderByState}
              allowedWatchlist
              useAI={useAI}
            />
          ))
        : null}
    </TableRow>
  );
};

export default React.memo(WatchlistRow, (prev, next) => {
  const rowValueSame = isEqual(omit(prev?.row, ['_id']), omit(next?.row, ['_id']));
  const watchlistSame = isEqual(prev?.activeWatchlistSymbols, next?.activeWatchlistSymbols);
  const orderStateSame = prev?.orderState === next?.orderState;
  const orderByStateSame = prev?.orderByState === next?.orderByState;
  // const isSame = (rowValueSame && watchlistSame && orderStateSame && orderByStateSame);
  // if (!isSame) {
    // console.log('WatchlistRow changed');
    // console.log(rowValueSame, watchlistSame, orderStateSame, orderByStateSame);
    // if (!rowValueSame) {
    //   console.log(prev?.row, next?.row);
    // }
  // }
  return rowValueSame && watchlistSame && orderStateSame && orderByStateSame;
});
